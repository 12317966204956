import { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux'
import {AiFillEdit, AiOutlineCloudDownload} from 'react-icons/ai'
import {MdDeleteForever} from 'react-icons/md'
import useOpenSrt from "../../../hooks/useOpenSrt";
import useDelete from '../../../hooks/useDelete';
import { HOST_URL } from '../../../config';
import { toast } from 'react-toastify';
import {reUpdateSingleStrToDacast} from "../../../features/video_team/videosUpload/videosSlice";






  function ViewTranslations({originalFileName, id}) {

    const { translations} = useSelector((state) => state.translations);
    const { video,dacast_subtitles } = useSelector((state) => state.videos);
    const [chapterTranslations, setChapterTranslations] = useState();
    const [chapterObjectArray, setChapterObjectArray] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
      // console.log('tranlations')
      if(translations.length > 0){
        
         // Filter the translations array based on the chapter ID
        const filteredTranslations = translations.filter((translation) => translation.chapter_id === id);
        
        setChapterTranslations(filteredTranslations);
      }
    }, [translations, id]);

    useEffect(() => {
      const chapter_array = []
        if (chapterTranslations && chapterTranslations.length > 0) {
          for(let i = 0; i < chapterTranslations.length; i++){
            if(chapterTranslations[i].tranlsation_filename){
              chapter_array.push(chapterTranslations[i])
            } 
          }
          setChapterObjectArray(chapter_array)
        }
      
     
    }, [chapterTranslations]);


    // useEffect(() => {
    //   dispatch(getChaptersByVideoId(video.id))
    //       .unwrap()
    //       .then((chapter_restult) => {
              
    //       })
    //       .catch((error) => {
    //           toast.error(error)
    //       });
    // }, []);


    const { openSrt } = useOpenSrt();

    // // custom hook to delete files
    const {handleDelete} = useDelete();


  
    const handleOptionClick = (filename, link) => {
      if(chapterObjectArray){   
        openSrt(filename, link, originalFileName)
      } 
    }

    const reuploadSubtitle = async(e, dacast_id,translation_id) =>{
      if(!window.confirm("Do you want re-upload this subtitle?")){
        return;
      }

      const data = {
          id: translation_id,
          dacast_id: dacast_id,
      }
      dispatch(reUpdateSingleStrToDacast(data))
      .unwrap()
      .then(() => {
          toast.success('Files uploaded')
      })
      .catch((error) => {
          toast.error(error)
      });

    }


  
    if(chapterObjectArray.length < 1){
      return (
        <>
        <h3 style={{marginTop: '50px' }} className="actions-title underline">Translations: 0</h3>
        </>
        
      )
    }
  
    return (
      <>
        <h3 style={{marginTop: '50px' }} className="actions-title underline">Translations</h3>
        <div>
        {chapterObjectArray.map((chapter) => (
            <div key={chapter.tranlsation_id} className="view-translation-container">
            <div className="single-translation">
              <span className="flex-center">
                <p className="language-title">
                  {chapter.language_title}
                </p>
              </span>
              <div className="translations-buttons-container">
              <div className="btn-actions" onClick={(e) => reuploadSubtitle(
                e,
                chapter.dacast_id,
                chapter.tranlsation_id,
              )}>
              {chapter.dacast_id ? (
                    dacast_subtitles?.filter(
                        ds => 
                            ds.id === chapter.dacast_id &&
                            ds.subtitles?.filter(sub => {
                                const normalizedLanguageCode = chapter.language_code?.toLowerCase() === 'es-es'
                                    ? 'es'
                                    : (chapter.language_code?.toLowerCase() === 'ja-ja'
                                        ? 'ja'
                                            : chapter.language_code?.toLowerCase());

                                return sub['subtitle-language-short-name']?.toLowerCase() === normalizedLanguageCode;
                            }).length
                    ).length
                        ? ' 🟢 dacast'
                        : '❌'
                ) : '🟠'}
                </div>

              <div className="btn-actions modified" >{new Date(chapter.modified_at).toLocaleDateString('en-GB')}</div>
                <button className='btn-actions' onClick={() => handleOptionClick(chapter.tranlsation_filename, chapter.audio_link)}>
                <AiFillEdit style={{marginRight: '15px'}} size={20}/> EDIT / VIEW
                </button> 
                  <a className='btn-actions' rel="noreferrer" href={`${HOST_URL}video/file/download/${chapter.tranlsation_filename}`} ><AiOutlineCloudDownload style={{marginRight: '15px'}} size={20}/>DOWNLOAD</a>
                  <button className='btn-actions' onClick={() => handleDelete(chapter.tranlsation_id, 'translation', video.id)}>
                  <MdDeleteForever style={{marginRight: '15px'}} size={20}/> DELETE 
                  </button> 
              </div>
              </div>
              
            </div>
         ))}
        </div> 
      </>
    );
  }
  
  export default ViewTranslations;